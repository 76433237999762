import React from 'react';

import {
  ArrowLeftRight,
  ArrowUpWideNarrow,
  BarChart2,
  Heart,
  HeartHandshake,
  Lock,
  Megaphone,
  MessagesSquare,
  Search,
  Share,
  Shield,
  Sliders,
  Sparkles,
  Timer,
  TrendingUp,
} from 'lucide-react';

import LandingHelmet from 'common/helmets/LandingHelmet';
import { SVGLogos } from 'common/www/assets/CompanyLogos';
import LandingImages from 'common/www/assets/LandingImages';
import Integration from 'common/www/LandingV3/Data/IntegrationData';
import Testimonial from 'common/www/LandingV3/Data/TestimonialData';
import LandingBento, { type Props as Bento } from 'common/www/LandingV3/LandingBento';
import LandingContentBlock, { type Props as Block } from 'common/www/LandingV3/LandingContentBlock';
import LandingCTA, { type Props as CTA } from 'common/www/LandingV3/LandingCTA';
import LandingHero, {
  AutopilotCallout,
  type Props as Hero,
} from 'common/www/LandingV3/LandingHero/';
import {
  LandingHeroVideo,
  type LandingHeroVideoProps,
} from 'common/www/LandingV3/LandingHeroVisual';
import LandingIntegrations, {
  type Props as Integrations,
} from 'common/www/LandingV3/LandingIntegrations';
import LandingLogos, { type Props as Logos } from 'common/www/LandingV3/LandingLogos';
import LandingStats, { type Props as Stats } from 'common/www/LandingV3/LandingStats';
import {
  LandingTabsWithBody,
  type TabWithBodyProps as Tabs,
} from 'common/www/LandingV3/LandingTabs';
import LandingTestimonials, {
  type Props as Testimonials,
} from 'common/www/LandingV3/LandingTestimonials';

import 'css/components/www/_LandingV3.scss';

const VideoID = 'pil7s7hbw3';

const heroSection: Hero = {
  copy: {
    title: 'Build better products with customer feedback',
    subtitle:
      'Centralize product feedback to uncover insights and make\n informed product decisions',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
    cost: 'No credit card required',
  },
  pretitle: <AutopilotCallout />,
};

const videoSection: LandingHeroVideoProps = {
  copy: {
    playButton: 'See how it works',
  },
  videoID: VideoID,
  image: {
    alt: 'Collect all feedback from different sources in Canny, and see breakdowns of all your data.',
    src: LandingImages.hero['1x'],
    srcSet: [
      {
        src: LandingImages.hero['0.5x'],
        sizeMarker: '640w',
      },
      {
        src: LandingImages.hero['1x'],
        sizeMarker: '1280w',
      },
      {
        src: LandingImages.hero['1.5x'],
        sizeMarker: '1920w',
      },
    ],
    sizes: '(max-width: 420px) 320px, (max-width: 780px) 640px, 960px',
    width: 1920,
    height: 1080,
  },
};

const logosSection: Logos = {
  copy: {
    title: 'Trusted by both industry leaders and startups',
  },
  logos: [
    // first row
    {
      company: 'ahrefs',
      url: '/case-studies/ahrefs',
      image: {
        src: SVGLogos.Ahrefs,
        alt: '',
        width: 110,
        height: 28,
      },
    },
    {
      company: 'circleci',
      url: '/case-studies/circleci',
      image: {
        src: SVGLogos.CircleCI,
        alt: '',
        height: 30,
        width: 134,
      },
    },
    {
      company: 'hive',
      url: '/case-studies/hive',
      image: {
        src: SVGLogos.Hive,
        alt: '',
        height: 30,
        width: 136,
      },
    },
    {
      company: 'axios',
      url: '/case-studies/axios',
      image: {
        src: SVGLogos.Axios,
        alt: '',
        height: 28,
        width: 110,
      },
    },
    {
      company: 'clickup',
      url: '/case-studies/clickup',
      image: {
        src: SVGLogos.ClickUp,
        alt: '',
        height: 32,
        width: 131,
      },
    },
    // second row
    {
      company: 'Typeform',
      url: '/case-studies/',
      image: {
        src: SVGLogos.Typeform,
        alt: '',
        height: 28,
        width: 86,
      },
    },
    {
      company: 'harness',
      url: '/case-studies/',
      image: {
        src: SVGLogos.Harness,
        alt: '',
        height: 30,
        width: 82,
      },
    },
    {
      company: 'mercury',
      url: '/case-studies/mercury',
      image: {
        src: SVGLogos.Mercury,
        alt: '',
        height: 30,
        width: 152,
      },
    },
    {
      company: 'strapi',
      url: '/case-studies/strapi',
      image: {
        src: SVGLogos.Strapi,
        alt: '',
        height: 32,
        width: 134,
      },
    },
    {
      company: 'missive',
      url: '/case-studies/missive',
      image: {
        src: SVGLogos.Missive,
        alt: '',
        height: 32,
        width: 182,
      },
    },
  ],
};

const tabsSection: Tabs = {
  copy: {
    title: 'Your complete customer feedback platform',
    subtitle:
      'Organize all your feedback in one place, prioritize what to build next, and keep everyone updated',
  },
  tabs: [
    {
      title: 'Collect feedback',
      body: 'Gather, analyze, and organize feedback in a centralized location',
      icon: MessagesSquare,
      image: {
        src: LandingImages.collect,
        alt: 'Many industry standard services can feed into Canny.',
        width: 1532,
        height: 1440,
      },
      imageStyleAdjustments: { top: 0 },
      tabBody: [
        'Stop letting ideas slip through the cracks. Collect, analyze, and organize feedback and feature requests in one dedicated tool.',
        [
          'Automatically capture customer feedback from conversations with Autopilot',
          'Detect and merge duplicates so you can quantify ideas',
          'Set up a feedback portal to let users share requests directly from your site',
          'Integrate customer support tools to let your team capture feedback on behalf of users',
        ],
      ],
    },
    {
      title: 'Analyze feedback',
      body: 'Uncover valuable customer insights to make better product decisions',
      icon: Search,
      image: {
        src: LandingImages.analyze,
        alt: 'Get breakdown tables with information such as company spend, renewal status, and priority.',
        width: 1532,
        height: 1172,
      },
      imageStyleAdjustments: {
        top: '10%',
      },
      tabBody: [
        'Uncover valuable customer insights to make better product decisions',
        [
          'See which features could have the highest revenue impact',
          'Connect customer data to see who wants which features',
          'Create user segments to see feedback from groups you care about most',
          'Clarify use cases up front and get more context from users',
        ],
      ],
    },
    {
      title: 'Prioritize requests',
      body: 'Develop formulas to automatically score feedback and feature requests',
      icon: ArrowUpWideNarrow,
      image: {
        src: LandingImages.prioritize,
        alt: 'Use inputs such as impact and priority to score your tasks.',
        width: 1246,
        height: 1288,
      },
      imageStyleAdjustments: {
        paddingRight: 33,
        top: '10%',
      },
      tabTitle: 'Prioritize feature requests',
      tabBody: [
        'Build your prioritization formula to score feedback and feature requests. Focus on the features with the biggest impact.',
        [
          'Customize your formula with impact and effort factor weights',
          'Create custom post fields unique to your business',
          'Sync properties from Hubspot or Salesforce to factor in opportunity revenue',
          'Seamlessly integrate with your project management tool of choice',
        ],
      ],
    },
    {
      title: 'Build roadmaps',
      body: 'Create public or private roadmaps to keep everyone updated on your progress',
      icon: BarChart2,
      iconClassModifier: 'rotate-90',
      image: {
        src: LandingImages.roadmaps,
        alt: 'Create roadmap tables where you can view status, impact, votes, and where each task comes from.',
        width: 1452,
        height: 1352,
      },
      imageStyleAdjustments: {
        top: '7%',
      },
      tabTitle: 'Build your roadmap',
      tabBody: [
        'Create a public or private roadmap to keep everyone updated',
        [
          'Add your top feature requests to your roadmap in a single click',
          'Connect to project management tools like Jira, ClickUp, and Asana',
          'Create roadmaps for specific projects or use cases',
        ],
      ],
    },
    {
      title: 'Share updates',
      body: 'Build a public changelog to keep your audience informed and engaged',
      icon: Megaphone,
      image: {
        src: LandingImages.changelog,
        alt: 'Write changelogs, adjust privacy and email notification settings, and more.',
        width: 1180,
        height: 1331,
      },
      imageStyleAdjustments: {
        paddingRight: 70,
        top: '7%',
      },
      tabBody: [
        'Build a changelog to keep everyone engaged',
        [
          'Publish detailed release notes linked to your feature requests',
          'Automatically notify users who voted on those feature requests',
          'Boost customer engagement, retention, and feature adoption',
        ],
      ],
    },
  ],
};

const aiBentoBoxSection: Bento = {
  copy: {
    title: 'Put feedback management on Autopilot',
    subtitle: 'Powerful AI features handle as many of your manual feedback tasks as you like',
  },
  boxes: [
    {
      backgroundColor: 'indigo50',
      title: 'Feedback Discovery',
      body: 'Automatically capture and deduplicate customer feedback from customer conversations',
      image: {
        src: LandingImages.autopilot1,
        alt: 'Many different sources such as Canny, Intercom, Help Scout, Gong, and G2 Reviews can feed into Canny.',
        width: 897,
        height: 302,
      },
    },
    {
      backgroundColor: 'sky50',
      title: 'Smart Replies',
      body: 'Respond to feedback with clarifying questions to uncover deeper insights',
      image: {
        src: LandingImages.autopilot2,
        alt: 'A user requests a feature and an automatic reply is sent asking for more details.',
        width: 584,
        height: 298,
      },
    },
    {
      backgroundColor: 'blue50',
      title: 'Comment Summaries',
      body: 'Instantly recap lengthy feedback threads to understand core use cases',
      image: {
        src: LandingImages.autopilot3,
        alt: 'A summarize button allows you to quickly summarize comments.',
        width: 584,
        height: 298,
      },
    },
  ],
};

const statsSection: Stats = {
  copy: {
    title: 'Leading companies use Canny to close the feedback loop',
  },
  stats: [
    {
      metric: '50K+',
      subtitle: 'registered companies',
    },
    {
      metric: '1M+',
      subtitle: 'product updates sent',
    },
    {
      metric: '15M+',
      subtitle: 'feedback items captured',
    },
  ],
};

const contentBlocks: Block[] = [
  {
    copy: {
      title: 'Create a community around your product',
      subtitle: 'Put your users center-stage to share their feedback and discuss ideas',
    },
    image: {
      alt: 'An email following up to a specific user for a feedpack post marked as complete.',
      src: LandingImages.block1['1x'],
      srcSet: [
        {
          src: LandingImages.block1['0.75x'],
          sizeMarker: '372w',
        },
        {
          src: LandingImages.block1['1x'],
          sizeMarker: '744w',
        },
        {
          src: LandingImages.block1['2x'],
          sizeMarker: '1488w',
        },
      ],
      sizes: '(max-width: 420px) 186px, (max-width: 780px) 372px, 744px',
      width: 744,
      height: 580,
    },
    points: [
      {
        icon: MessagesSquare,
        title: 'Discuss',
        body: 'Foster discussion about your product and feature requests',
      },
      {
        icon: Search,
        title: 'Understand',
        body: 'Learn more about user motivations and pain points',
      },
      {
        icon: Share,
        title: 'Share',
        body: 'Keep customers updated as you\u00a0work on their ideas',
      },
      {
        icon: Heart,
        title: 'Engage',
        body: 'Let your team connect with your most engaged users',
      },
    ],
  },
  {
    copy: {
      title: "Built to support your team's needs",
      subtitle: 'Thousands of teams like yours rely on Canny to help them build better products',
    },
    image: {
      alt: 'Options such as tags and user permissions, to allow the following of SOC & GDPR compliance.',
      src: LandingImages.block2['1x'],
      srcSet: [
        {
          src: LandingImages.block2['0.75x'],
          sizeMarker: '372w',
        },
        {
          src: LandingImages.block2['1x'],
          sizeMarker: '744w',
        },
        {
          src: LandingImages.block2['2x'],
          sizeMarker: '1488w',
        },
      ],
      sizes: '(max-width: 420px) 186px, (max-width: 780px) 372px, 744px',
      width: 744,
      height: 600,
    },
    points: [
      {
        icon: TrendingUp,
        title: 'Scalable',
        body: 'Grow from startup to enterprise\u00a0with flexible plans',
      },
      {
        icon: HeartHandshake,
        title: 'Supportive',
        body: 'Rely on our award-winning customer support',
      },
      {
        icon: Shield,
        title: 'Secure',
        body: 'Rest easy with best-in-class security and compliance',
      },
      {
        icon: Lock,
        title: 'Private',
        body: 'Control whether your feedback and roadmaps are public\u00a0or private',
      },
    ],
  },
  {
    copy: {
      title: 'Easy to set up and use',
      subtitle: "Built with usability in mind. From quick setup to daily use, you'll love using it",
    },
    image: {
      alt: "Customization of a company's color and theme.",
      src: LandingImages.block3['1x'],
      srcSet: [
        {
          src: LandingImages.block3['0.75x'],
          sizeMarker: '390w',
        },
        {
          src: LandingImages.block3['1x'],
          sizeMarker: '780w',
        },
        {
          src: LandingImages.block3['2x'],
          sizeMarker: '1560w',
        },
      ],
      sizes: '(max-width: 420px) 195px, (max-width: 780px) 390px, 780px',
      width: 780,
      height: 566,
    },
    points: [
      {
        icon: Timer,
        title: 'Quick',
        body: 'Install Canny and start collecting feedback in minutes',
      },
      {
        icon: ArrowLeftRight,
        title: 'Easy',
        body: 'Import your existing feedback with a simple upload',
      },
      {
        icon: Sparkles,
        title: 'User-friendly',
        body: 'Enjoy working with a modern and intuitive UI',
      },
      {
        icon: Sliders,
        title: 'Customizable',
        body: 'Customize Canny to match your brand and site identity',
      },
    ],
  },
];

const integrationsSection: Integrations = {
  copy: {
    title: 'Works well with your existing workflow',
    subtitle:
      'Canny integrates with tools you already use for customer service, project management, and more',
    seeAllLink: 'See all integrations',
  },
  integrations: [
    // first row
    [
      Integration.Slack,
      Integration.ClickUp,
      Integration.Zapier,
      Integration.Jira,
      Integration.Asana,
    ],
    // second row
    [
      Integration.Intercom,
      Integration.Salesforce,
      Integration.Hubspot,
      Integration.GitHub,
      Integration.Zendesk,
    ],
  ],
};

const testimonialSection: Testimonials = {
  copy: {
    title: "Here's why thousands of\u00a0teams count on Canny",
  },
  categorizedTestimonials: {
    product: [Testimonial.CircleCI(), Testimonial.AgencyAnalytics(), Testimonial.Mercury()],
    success: [Testimonial.Aryeo(), Testimonial.Figured(), Testimonial.Knak()],
    sales: [Testimonial.Strapi(), Testimonial.Jane(), Testimonial.GiveButter()],
    marketing: [Testimonial.Hive(), Testimonial.Axios(), Testimonial.AgencyAnalytics()],
    founders: [Testimonial.ClickUp(), Testimonial.Missive(), Testimonial.Document360()],
  },
};

const cta: CTA = {
  copy: {
    title: 'Ready to get started?',
    demoButton: 'Request a demo',
    registerButton: 'Get started',
  },
};

const LandingV3 = () => {
  return (
    <>
      <LandingHelmet />
      <main className="LandingV3" id="main">
        <LandingHero {...heroSection} />
        <LandingHeroVideo {...videoSection} />
        <LandingLogos {...logosSection} />
        <LandingTabsWithBody {...tabsSection} />
        <LandingBento {...aiBentoBoxSection} />
        <LandingStats {...statsSection} />
        {contentBlocks.map((block, index) => (
          <LandingContentBlock {...block} key={index} flip={index % 2 === 1} />
        ))}
        <LandingIntegrations {...integrationsSection} />
        <LandingTestimonials {...testimonialSection} />
        <LandingCTA {...cta} />
      </main>
    </>
  );
};

export default LandingV3;
