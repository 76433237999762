import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';

import assessment from 'common/reducers/assessment';
import cookies from 'common/reducers/cookies';
import referrer from 'common/reducers/referrer';
import serverResponse from 'common/reducers/serverResponse';
import viewer from 'common/reducers/viewer';

export default combineReducers({
  cookies,
  reduxAsyncConnect,
  routing: routerReducer,
  serverResponse,
  viewer,
  assessment,
  referrer,
});
